import { type DateTime, Interval } from 'luxon';
import useEvent from 'react-use-event-hook';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useTimeZonedDateUtils } from '@/hooks/useTimeZonedDateUtils';
import type { DecoratedEntry } from '@/pages/Schedule/types';
import { datePartInZone } from '@/utils/dates';
import { useScheduleContext } from '../contexts';
import { type CreateDraftEntryOptions, useDraftEntryStore } from '../stores';

export const useCreateDraftEntry = () => {
  const { scheduleId, startDate, endDate, timeZone } = useScheduleContext();
  const { local } = useTimeZonedDateUtils();
  const { trackEvent } = useAnalytics();
  const createDraftEntry = useDraftEntryStore(
    (state) => state.createDraftEntry
  );

  const wrappedCreateDraftEntry = useEvent(
    (
      date?: DateTime,
      entryProps?: Partial<DecoratedEntry>,
      options?: CreateDraftEntryOptions
    ) => {
      if (!date) {
        const dateInterval = Interval.fromDateTimes(startDate, endDate);
        const now = local();
        date = dateInterval.contains(now) ? now.startOf('day') : startDate;
      }
      // Take just the date part from the specified date ignoring any time.
      // Without this, creating entries will fail if the app loads in one timezone,
      // the zone changes, and then you try to create an entry.
      date = datePartInZone(date, timeZone);
      createDraftEntry(date, scheduleId, entryProps, options);

      trackEvent('entry:new', { composer: options?.composer ?? 'modal' });
    }
  );

  return {
    createDraftEntry: wrappedCreateDraftEntry,
  };
};
